import { Module } from "vuex";
import { CourseContentModel, CourseMaterialsModel, CourseMetaData, CourseState } from "./model";
import api from "@/services/api";
import config from "@/config";
import { BaseIcon } from "../Icon/model";
import constants from "@/utils/constants";

const CourseModule: Module<CourseState, unknown> = {
  namespaced: true,
  state() {
    return {
      courses: { content: [] },
      editingCourse: {
        id: "",
        includesPhysicalBook: true,
        learningFormatType: constants.eventType.materialsOnly,
        learnerMaterials: [],
        name: "",
        contentStackId: "",
        contentStackProductId: "",
        recommendedMaxSeats: 1,
        recommendedSessionCount: 0,
        order: "",
        eap: [],
      },
      newCourse: true,
      metaData: [],
    };
  },
  getters: {
    getCourseIcon(state: CourseState): (contentStackId: string) => BaseIcon {
      return (contentStackId: string) => {
        const base = state.metaData.find((e) => e.uid === contentStackId);
        const url = base ? base.familyIcon : "";
        return { url } as BaseIcon;
      };
    },
    getCourseLogo(state: CourseState): (contentStackId: string) => string {
      return (contentStackId: string) => {
        const base = state.metaData.find((e) => e.uid === contentStackId);
        return base ? base.familyLogo : "";
      };
    },
    getCourseAbbreviation(state: CourseState): (contentStackId: string) => string {
      return (contentStackId: string) => {
        const base = state.metaData.find((e) => e.uid === contentStackId);
        return base ? base.abbreviation : "";
      };
    },
    getCourseMetaData(state: CourseState): (contentStackId: string) => CourseMetaData | undefined {
      return (contentStackId: string) => {
        return state.metaData.find((e) => e.uid === contentStackId);
      };
    },
  },
  mutations: {
    setCourseState(state: CourseState, courseStateOptions: Partial<CourseState>): void {
      Object.assign(state, courseStateOptions);
    },
    setEditingCourse(state: CourseState, courseStateOptions: Partial<CourseContentModel>): void {
      Object.assign(state.editingCourse, courseStateOptions);
    },
  },
  actions: {
    async getCourses({ commit }, eap?: string[]): Promise<void> {
      if (eap == undefined) eap = config.eap;
      const courses = await api.course.getCourses(eap);
      if (courses) commit("setCourseState", { courses });
    },
    removeMaterialEdit({ commit, state }, idx: number): void {
      const courseMaterialsArr = JSON.parse(JSON.stringify(state.editingCourse.learnerMaterials));
      courseMaterialsArr.splice(idx, 1);
      commit("setEditingCourse", { learnerMaterials: courseMaterialsArr });
    },
    updateMaterialEdit({ commit, state }, update: { idx: number; model: CourseMaterialsModel }): void {
      const courseMaterialsArr = JSON.parse(
        JSON.stringify(state.editingCourse.learnerMaterials)
      ) as CourseMaterialsModel[];
      const updatedRecord = courseMaterialsArr[update.idx];
      Object.assign(updatedRecord, update.model);
      courseMaterialsArr.splice(update.idx, 1, updatedRecord);
      commit("setEditingCourse", { learnerMaterials: courseMaterialsArr });
    },
    addMaterialEdit({ commit, state }, model: CourseMaterialsModel) {
      const courseMaterialsArr = JSON.parse(
        JSON.stringify(state.editingCourse.learnerMaterials)
      ) as CourseMaterialsModel[];
      courseMaterialsArr.push(model);
      commit("setEditingCourse", { learnerMaterials: courseMaterialsArr });
    },
  },
};

export default CourseModule;
