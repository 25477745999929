import { http } from "@/services/http";
import routes from "@/services/routes";
import { CertificationMeta, Certification, PlatformUserModel } from "@/store/modules/User/model";
import responses from "@/services/responses";

const PlatformUser = {
  getCertifiedUsers(
    salesForceId: string,
    courseId: string,
    type: "trainers" | "moderators"
  ): Promise<PlatformUserModel[]> {
    return http
      .post<PlatformUserModel[]>(routes("certifications").PLATFORM_USERS, {
        salesForceId,
        courseId,
        type,
      })
      .then((response) => response.data)
      .catch((error) => {
        if (error.response && error.response.status === 404) console.clear();
        return [];
      });
  },
  getPlatformUser(id: string): Promise<PlatformUserModel | undefined> {
    return http
      .get<PlatformUserModel>(routes(id).PLATFORM_USERS)
      .then((response) => response.data)
      .catch((error) => {
        console.log("platformUser verification: " + error.message);
        return undefined;
      });
  },
  createPlatformUser(user: PlatformUserModel): Promise<string> {
    console.log("platformUser record being created");
    let url = routes().PLATFORM_USERS;
    const grant = localStorage.getItem("CL-auto-grant");
    let hadGrant = false;
    if (grant) {
      hadGrant = true;
      url += "?grant=" + grant;
    }
    return http
      .post<string>(url, user)
      .then((response) => {
        if (response.status == 201) {
          if (hadGrant) {
            localStorage.removeItem("CL-auto-grant");
          }
        }
        return response.data;
      })
      .catch((error) => {
        console.log("platformUser record creation failed: " + JSON.stringify(error));
        return responses.FAILURE;
      });
  },
  updateCertification(id: string, certification: Certification): Promise<PlatformUserModel | undefined> {
    return http
      .put<PlatformUserModel>(`${routes().PLATFORM_USERS}${id}`, { certifications: [certification] })
      .then((response) => response.data)
      .catch((error) => {
        console.log("Error update certification " + error);
        return undefined;
      });
  },
  updateModerator(
    id: string,
    update: {
      moderator?: Certification;
      removeModeratorClient?: string;
      removeAllModerators?: boolean;
    }
  ): Promise<PlatformUserModel | undefined> {
    let body: ModeratorUpdate = {};
    if (update.moderator) {
      body = { moderators: [update.moderator] };
    } else if (update.removeModeratorClient) {
      body = { removeModeratorClient: update.removeModeratorClient };
    } else if (update.removeAllModerators) {
      body = { removeAllModerators: update.removeAllModerators };
    }
    return http
      .put<PlatformUserModel>(`${routes().PLATFORM_USERS}${id}`, body)
      .then((response) => response.data)
      .catch((error) => {
        console.log("Error adding moderator " + error);
        return undefined;
      });
  },
  getCertMetadata(): Promise<CertificationMeta[]> {
    return http
      .post<CertMetaDataResponse>(routes("self_certifications").PLATFORM_USERS)
      .then((response) => response.data.certifications)
      .catch((error) => {
        console.error("Error getting self certification metadata" + error);
        return [];
      });
  },
};

interface ModeratorUpdate {
  moderators?: Certification[];
  removeModeratorClient?: string;
  removeAllModerators?: boolean;
}
interface CertMetaDataResponse {
  certifications: CertificationMeta[];
}
export default PlatformUser;
